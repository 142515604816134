import React from 'react';
import {Container, Row, Col, Navbar, NavbarBrand} from "reactstrap";

import graphic from './assets/graphic.jpg';
import rocket from './assets/rocket.jpg';
import team from './assets/team.jpg';
import documentation from './assets/documentation.jpg';
import prototype from './assets/prototype.jpg';
import projectManagement from './assets/project-management.jpg';

import Header from "./components/Header";
import Image from "./Image";
import Footer from "./Footer";
import Subheading from "./components/Subheading";

function App() {
  return (
    <React.Fragment>
     <Header />

      <Container fluid style={{marginTop: "100px"}} className="p-0">
        <Row>
          <Col xs={12} id="anchorAbout">
            <img src={graphic} style={{width: "100%", maxWidth: "100%"}} alt="Generic graphic" />
          </Col>
          <div style={{backgroundColor: "#e26d5a", height: "10px"}} />
          <div style={{backgroundColor: "#044389", height: "7px"}} />
        </Row>
      </Container>

      <Container>

        <Row>
          <Col xs={12} className="p-4">
            <p>
              Charlbury Technology is a technical consultancy based near Oxford in the UK offering project management and software development services to SMEs and larger businesses.
            </p>
            <p>
              We can work with you at all stages of a software project - testing ideas, delivering a minimum viable product, managing the end-to-end delivery of a solution or working with you on a longer-term digital transformation programme.
            </p>

          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h1>Services</h1>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={4}>
            <Image src={rocket} alt="Picture of a rocket" />
          </Col>
          <Col xs={12} md={8} className="p-2" id="anchorProjectStartup">
            <Subheading>Project Startup</Subheading>
            <p>
              Are you right at the start of a project?
            </p>
            <p>
              We can work with you from the outset to produce a project brief for a new digital product.
              This work includes sketching out initial ideas, developing an outline business case and setting out a realistic implementation plan.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12} md={4}>
            <Image src={team} alt="Assembling a team" />
          </Col>
          <Col xs={12} md={8} className="p-2" id="anchorAssemblingATeam">
            <Subheading>Assembling a team</Subheading>
            <p>
              Different projects require different skills depending on the technologies used.
            </p>
            <p>
              We can help advise you on putting together a team with all of the expertise you may need, including front-end and back-end developers, and specialists in cloud architecture, storage, security, and UI/UX (user interface and experience) design.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12} md={4}>
            <Image src={prototype} alt="Rapid prototyping and MVP creation" />
          </Col>
          <Col xs={12} md={8} className="p-2" id="anchorRapidPrototyping">
            <Subheading>Creating a minimum viable product</Subheading>
            <p>
              Usually, the detailed requirements for a digital product are not clear at the start and there is a risk of spending time and money on features which are not used.
            </p>
            <p>
              The best way to manage this uncertainty is to create and launch a minimum viable product which includes only the core features.
              You can then change and expand your product incrementally, based on feedback from your customers.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12} md={4}>
            <Image src={projectManagement} alt="Project Management" />
          </Col>
          <Col xs={12} md={8} className="p-2" id="anchorProjectManagement">
            <Subheading>Project Management</Subheading>
            <p>
              We can provide day-to-day management of the implementation of your project using proven Agile techniques.
              We will be completely focused on your requirements and will help you prioritize resources on those features which will add most value to your business.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12} md={4}>
            <Image src={documentation} alt="Documentation" />
          </Col>
          <Col xs={12} md={8} className="p-2" id="anchorDocumentation">
            <Subheading>Documentation</Subheading>
            <p>
              We can prepare and maintain documentation for your project, including technical information for developers and team members who need to maintain it.
            </p>
            <p>
              We can also work with you to produce content, graphics and step-by-step video guides for end users.
            </p>
          </Col>
        </Row>

      </Container>

      <Footer />
    </React.Fragment>


  );
}

export default App;
