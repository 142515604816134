import React from 'react';
import { Container, Row, Col } from "reactstrap";

import { colours } from "./config";
import Subheading from "./components/Subheading";
import FooterContent from "./components/FooterContent";

const Footer = props => {
  return (
    <Container fluid className="p-0 mt-4" style={{backgroundColor: colours.vividSkyBlue, color: colours.jet}}>
      <Row className="p-4 my-4">
        <Col md={4} id="anchorContact">
          <Subheading>Tell us about your project</Subheading>
          <FooterContent>
            chris.sharpe@charlburytech.co.uk<br />
            +44 (0)1608 656370
          </FooterContent>
        </Col>
        <Col md={8}>
          <FooterContent>
            Charlbury Technology Limited is a company registered in England No. 10744709.  <br />
            ICO Registration No. ZB430398<br />
            Copyright &copy; 2017-2023 Charlbury Technology Limited. All rights reserved.
          </FooterContent>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer;