export const services = [
  {
    label: "Project Startup",
    anchor: "anchorProjectStartup"
  },
  {
    label: "Assembling a team",
    anchor: "anchorAssemblingATeam"
  },
  {
    label: "Creating a minimum viable product",
    anchor: "anchorRapidPrototyping"
  },
  {
    label: "Project Management",
    anchor: "anchorProjectManagement"
  },
  {
    label: "Documentation",
    anchor: "anchorDocumentation"
  },
];