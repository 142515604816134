import React from 'react';

const FooterContent = props => {
  return (
    <span style={{
      fontSize: "0.75em"
    }}>
      {props.children}
    </span>
  )
}

export default FooterContent;