import React, {useState} from 'react';
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Navbar,
  NavbarToggler,
  NavbarBrand,
} from "reactstrap";

import { services } from "../configuration";

import scrollToAnchor from "../scrollToAnchor";

import logo from "../assets/logo-horizontal.png";
import menu from "../assets/menu.svg";
import close from "../assets/close.svg";

const Header = () => {
  const [ isMenuOpen, setIsMenuOpen ] = useState(false)

  const toggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const goToPage = id => {
    setIsMenuOpen(false);
    scrollToAnchor(id);
  }

  return (
    <Navbar
      style={{
        backgroundColor: '#ffffff',
        opacity: 0.95
      }}
      fixed="top"
      expand="lg"
    >
      <NavbarBrand>
        <img src={logo} style={{
          maxHeight: "57px"
        }} alt="Charlbury Technology Logo" />
      </NavbarBrand>
      <NavbarToggler onClick={toggle}>
        {
          isMenuOpen === true
            ?
            <img src={close} style={{minHeight: "16px", border: 'none', cursor: 'pointer', marginRight: '10px'}} alt="Close menu" />
            :
            <img src={menu} style={{minHeight: "16px", border: 'none', cursor: 'pointer', marginRight: '10px'}} alt="Open menu" />
        }
      </NavbarToggler>
      <Collapse isOpen={ isMenuOpen } className="justify-content-start" navbar>
        <Nav navbar>
          <NavItem>
            <NavLink href="#" onClick={() => goToPage('anchorAbout')}>Home</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Services
            </DropdownToggle>
            <DropdownMenu>
              {
                services.map(service => {
                  return (
                    <DropdownItem key={service.label} onClick={() => goToPage(service.anchor)}>
                      {service.label}
                    </DropdownItem>
                  )
                })
              }
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <NavLink href="#" onClick={() => goToPage('anchorContact')}>Contact</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  )
}

export default Header;