import React from 'react';

const Subheading = props => {
  return (
    <h5 style={{
      fontFamily: "myriad-pro-semiextended",
      fontWeight: "600"
    }}>
      {props.children}
    </h5>
  )
}

export default Subheading;